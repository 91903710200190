<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <h1 class="text-white my-4">Welcome Back!</h1>
        <h4 class="text-white font-weight-normal"></h4>
      </div>
    </div>
    <div class="auth-side-form">
      <div class="auth-content login">
        <h3 class="mb-4 f-w-400 text-center">Forgot Password</h3>
        <form [formGroup]="forgotPasswordForm">
          <div class="form-group mb-3">
            <input type="email" [formControl]="forgotPasswordForm.controls.sEmail" class="form-control"
              placeholder="Email address" />
            <div class="text-danger" *ngIf="
                forgotPasswordForm.controls['sEmail'].hasError('required') &&
                (forgotPasswordForm.controls['sEmail'].dirty ||
                  forgotPasswordForm.controls['sEmail'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i> Email is
              required
            </div>
            <div class="text-danger" *ngIf="forgotPasswordForm.controls['sEmail'].hasError('pattern')">
              <i class="fa fa-exclamation-circle text-danger"></i>Please enter a
              valid email address
            </div>
          </div>
          <button [disabled]="forgotPasswordForm.invalid || btnDisable" (click)="submit()"
            class="btn btn-block btn-secondary mb-4">
            <span *ngIf="btnLoader" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="!btnLoader">Send Email</span>
          </button>
          <div class="login">
            <a class="go-back" style="color: #0099cc" routerLink="/">
              GO BACK TO LOGIN.
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
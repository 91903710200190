import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketHistoryComponent } from './ticket-history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardModule } from '../card/card.module';



@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [TicketHistoryComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    CardModule
  ],
  exports: [TicketHistoryComponent]
})
export class TicketHistoryModule { }

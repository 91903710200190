import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/theme/shared/service/auth.service";
import { UtilService } from "src/app/theme/shared/service/utils.service";
import { ValidatorsService } from "src/app/theme/shared/service/validators.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  btnLoader: boolean;
  btnDisable: boolean;
  fieldTextType: boolean;
  fieldTextType2: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    // private spinnerService: SpinnerServiceService,
    private fb: FormBuilder,
    private util: UtilService,
    private validatorsService: ValidatorsService
  ) {}

  ngOnInit() {
    // this.spinnerService.setDemoSubject(false);
    this.resetPasswordForm = this.fb.group(
      {
        nOTP: ["", Validators.required],
        sPassword: [
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validatorsService.passwordRegx),
          ]),
        ],
        sReTypePassword: [null, Validators.compose([Validators.required])],
      },
      {
        validator: MustMatch("sPassword", "sReTypePassword"),
      }
    );
  }

  get f() {
    return this.resetPasswordForm.controls;
  }
  submit() {
    this.btnLoader = true;
    this.btnDisable = true;
    this.resetPasswordForm.value.nOTP = Number(
      this.resetPasswordForm.value.nOTP
    );
    this.authService.resetPassword(this.resetPasswordForm.value).subscribe(
      (res) => {
        this.btnLoader = false;
        this.btnDisable = false;
        this.router.navigate(["/"]);

        this.util.notifySuccess(res.message);
      },
      (err) => {
        this.btnLoader = false;
        this.btnDisable = false;
      }
    );
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

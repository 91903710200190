import { state } from '@angular/animations';

// Package Import 
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { mergeMap } from 'rxjs/operators';
import { UtilService } from './utils.service';


@Injectable({
  providedIn: "root"
})
export class HttpInterceptorService implements HttpInterceptor {
  public count: number = 0;
  constructor(
    public router: Router,
    public util:UtilService
  ) { }
  /* intercept request and add token */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    return of(null).pipe(mergeMap(() => {
      if (request.url.endsWith('auth/login')
        || request.url.includes('/auth/password/forgot')
        || request.url.includes("auth/password/reset")
      ) {
        let urlSplit = this.router.url.split("/")
        var urldata = urlSplit[1]
        if (urldata === "reset-password" ) {
          // let token = JSON.parse(sessionStorage.getItem('Authorization'))
          let token = JSON.parse(localStorage.getItem('Authorization'))
          request = request.clone({
                        setHeaders: { Authorization : token}
          });
        }
        return next.handle(request)
          .pipe(
            tap(event => {
              if (event instanceof HttpResponse) {
                /* http response status code */

              }
            }, error => {
              if (error.status === 403|| error.status === 404 || error.status === 406 || error.status === 419 || error.status === 401 || error.status === 500) {
                this.errorMsg(error);
              }
            })
          )
      } else {
        let urlSplit = this.router.url.split("/")
        var urldata = urlSplit[1]
        if (urldata === "admin") { 
        //  let token = JSON.parse(sessionStorage.getItem('adminuser'))
         let token = JSON.parse(localStorage.getItem('adminuser'))
         if(token !== null){
            request = request.clone({
              setHeaders: { Authorization : token}
            });
         }
          
        }
        return next.handle(request)
          .pipe(
            tap(event => {
              if (event instanceof HttpResponse) {
              }
            }, error => {
              /* http response status code */
              if (error.status === 403 || error.status === 404 || error.status === 406 ||error.status === 409 || error.status === 419 || error.status === 500) {
                this.errorMsg(error);
              }
              if (error.status === 401) {
                localStorage.clear();
                this.router.navigate(['/'])
                this.errorMsg(error);
              }
            })
          )
      }
    }))
  };

  errorMsg(errData) {
   console.log(errData);
   
    this.util.notifyError(errData.error.message);

  }
}



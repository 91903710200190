import { Action } from "@ngrx/store";
import { ProfileActionTypes } from "./admin-profile.actions";

export const profileFeatureKey = "profile";

export interface ProfileData {
  data: {};
  message: string;
  error: {};
}

export const initialState: ProfileData = {
  data: {},
  message: "",
  error: {},
};

export function profileReducer(
  state = initialState,
  action: Action
): ProfileData {
  switch (action.type) {
    case ProfileActionTypes.LoadProfiles:
      return state;
    case ProfileActionTypes.LoadProfilesSuccess:
      return { ...state, data: action };
    case ProfileActionTypes.LoadProfilesFailure:
      return { ...state, error: action };
    default:
      return state;
  }
}

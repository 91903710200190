<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu
      >
        <div class="pro-head">
          <img
            [src]="sAvatar"
            onerror="this.src='assets/images/user/noimage.png';"
            class="img-radius"
            alt="User-Profile-Image"
          />
          <span>{{ sFullName | titlecase }}</span>
          <a
            href="javascript:"
            class="dud-logout"
            title="Logout"
            (click)="logout()"
          >
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li>
            <a
              href="javascript:"
              class="dropdown-item"
              [routerLink]="['/admin/edit-profile']"
              ><i class="feather icon-user"></i> Profile</a
            >
          </li>
          <li>
            <a
              href="javascript:"
              class="dropdown-item"
              [routerLink]="['/admin/change-password']"
              ><i class="feather icon-mail"></i> Change Password</a
            >
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>

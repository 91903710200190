import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/theme/shared/service/auth.service";
import { UtilService } from "src/app/theme/shared/service/utils.service";
import { ValidatorsService } from "src/app/theme/shared/service/validators.service";

@Component({
  selector: "app-auth-signin",
  templateUrl: "./auth-signin.component.html",
  styleUrls: ["./auth-signin.component.scss"],
})
export class AuthSigninComponent implements OnInit {
  adminLoginForm: FormGroup;
  btnLoader: boolean;
  btnDisable: boolean;
  fieldTextType: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private util: UtilService,
    private validatorsService: ValidatorsService
  ) {}

  get controls() {
    return this.adminLoginForm.controls;
  }

  ngOnInit() {
    this.adminLoginForm = this.fb.group({
      sEmail: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.validatorsService.emailRegx),
        ]),
      ],
      sPassword: ["", Validators.required],
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  adminLogin() {
    this.btnLoader = true;
    this.btnDisable = true;

    this.authService.login(this.adminLoginForm.value).subscribe(
      (user) => {
        this.btnLoader = false;
        this.btnDisable = false;
        // sessionStorage.setItem('adminuser',  JSON.stringify(user.data.sToken));
        // sessionStorage.setItem('Admin Type', btoa('admin'));
        localStorage.setItem("adminuser", JSON.stringify(user.data.sToken));
        localStorage.setItem("Admin Type", btoa("admin"));
        this.router.navigate(["/admin/dashboard"]);

        this.util.notifySuccess(user.message);
      },
      (err) => {
        this.btnLoader = false;
        this.btnDisable = false;
      }
    );
  }
}

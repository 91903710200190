import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidatorsService } from "src/app/theme/shared/service/validators.service";
import { UtilService } from "src/app/theme/shared/service/utils.service";
import { AuthService } from "src/app/theme/shared/service/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  data: any = [];
  forgotPasswordForm: FormGroup;
  btnLoader = false;
  public imagePath = "assets/images/logo-dark.png";
  btnDisable = false;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private util: UtilService,
    private ValidatorsService: ValidatorsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this._formBuilder.group({
      sEmail: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.ValidatorsService.emailRegx),
        ]),
      ],
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  submit() {
    this.btnLoader = true;
    this.btnDisable = true;
    this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
      (response) => {
        this.btnLoader = false;
        this.btnDisable = false;
        let token = response.headers.get("authorization");

        localStorage.setItem("Authorization", JSON.stringify(token));
        this.util.notifySuccess(response.body.message);
        this.router.navigate(["/reset-password"]);
        this.forgotPasswordForm.reset();
      },
      (err) => {
        this.btnLoader = false;
        this.btnDisable = false;
      }
    );
  }
}

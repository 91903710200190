import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AdminDashboardService } from '../../../../shared/service/admin-dashboard.service';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../../app.reducer';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  sAvatar :any;
  sFullName: string;
  constructor( 
    private store: Store<fromRoot.State>,
    private router: Router, 
    private adminDashboardService: AdminDashboardService) { 
  }

  ngOnInit() { 
    this.store.select('Profile').subscribe((data: any) => {
      if (data['data'].user) {
        this.sFullName = data['data'].user.sFirstname + " " + data['data'].user.sLastname;
        this.sAvatar = data['data'].user.sAvatar;
      } 
    });
  }

  logout(){
    Swal.fire({
      title: 'Do you want to Logout?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminDashboardService.adminLogout().subscribe(res => {
            localStorage.clear();
            this.router.navigate(['/'])
        });
      }
    })
   
  }
}

import { ToastrModule } from 'ngx-toastr';
import { AdminComponent } from './admin.component';

import { LoginGuard } from './../../shared/guards/login.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';




const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../../../pages/admin-dashboard/admin-dashboard.module').then(module => module.AdminDashboardModule),
        canActivate: [LoginGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('../../../pages/users/users.module').then(module => module.UsersModule),
        canActivate: [LoginGuard],
        data: {
          title: 'Manage Users',
          status: false
        }
      },
      {
        path: 'shop-catergory',
        loadChildren: () => import('../../../pages/shop-category/shop-category.module').then(module => module.ShopCategoryModule),
        canActivate: [LoginGuard],
        data: {
          title: 'Shop Categories',
          status: false
        }
      },
      {
        path: 'shop-nft',
        loadChildren: () => import('../../../pages/shop-nft/shop-nft.module').then(module => module.ShopNftModule),
        canActivate: [LoginGuard],
        data: {
          title: 'Shop NFT',
          status: false
        }
      },
      {
        path: 'transaction',
        loadChildren: () => import('../../../pages/transaction/transaction.module').then(module => module.TransactionModule),
        canActivate: [LoginGuard],
        data: {
          title: 'Transaction',
          status: false
        }
      },
      {
        path: 'virtual-token',
        loadChildren: () => import('../../../pages/referral-tokens/referral-tokens.module').then(module => module.ReferralTokensModule),
        canActivate: [LoginGuard]
      },
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

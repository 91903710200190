import { Injectable } from '@angular/core';
import { AbstractControl, FormControl} from '@angular/forms';



@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

    emailRegx = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}";
    passwordRegx = '^(?=.*[0-9])(?!.* )(?=.*[A-Za-z]).{6,12}$$';
    phoneRegx = '^[0-9]{10}$';
    usernameRegex = '^[a-z0-9]*$';
    promocodeRegex = '^[a-zA-Z0-9]*$';
    postalcodeRegex = "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$";
    ifscRegex = "^[A-Z]{4}0[A-Z0-9]{6}$";
    accountNoRegex = "^[0-9]{9,18}";
    numberonly = '^[^0-9]*';
    reporttitle = '^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$'
    
    constructor() {
    }

    public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    public removeSpaces(control: FormControl) {
        if (control && control.value && !control.value.replace(/ /g,'').length) {
          control.setValue('');
        }
        return null;
      }
}
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BidHistoryComponent } from "./bid-history.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CardModule } from "../card/card.module";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [BidHistoryComponent],
  imports: [CommonModule, NgxDatatableModule, CardModule],
  exports: [BidHistoryComponent],
})
export class BidHistoryModule {}

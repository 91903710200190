import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class UtilService {
      
  constructor(private toastyService:ToastrService) {
  }

  notifySuccess(data){
    this.toastyService.success(data, null, {
      enableHtml: true,
      timeOut: 3000,
      tapToDismiss: true,
      messageClass: "success",
      positionClass: 'toast-top-right',
      });
  }

  notifyErrorImageType(){
    this.toastyService.error("Image Format should be jpg,png,jpeg.", null, {
      enableHtml: true,
      timeOut: 3000,
      messageClass: 'danger',
      tapToDismiss: true,
      positionClass: 'toast-top-right'
    });
  }

  notifyErrorImageSize(){
    this.toastyService.error("An image should be maxed 1 MB.", null, {
      enableHtml: true,
      timeOut: 3000,
      messageClass: 'danger',
      tapToDismiss: true,
      positionClass: 'toast-top-right'
    });
  }

  notifyError(data){
    this.toastyService.error(data, null, {
      enableHtml: true,
      timeOut: 3000,
      messageClass: 'danger',
      tapToDismiss: true,
      positionClass: 'toast-top-right'
    });
  }

  notifyInfo(data){
    this.toastyService.info(data, null, {
      enableHtml: true,
      timeOut: 3000,
      tapToDismiss: true,
      messageClass: "info",
      positionClass: 'toast-top-right',
    })
  }

  
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  
  public getDirtyValues(form: any) {
    const dirtyValues = {};
    Object.keys(form.controls).forEach(c => {
      const currentControl = form.get(c);
      if (currentControl.dirty) {
        dirtyValues[c] = currentControl.value;
      }
    });
    return dirtyValues;
  }



}
import { Injectable } from "@angular/core";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { AdminDashboardService } from "src/app/theme/shared/service/admin-dashboard.service";
import * as Profile from "./admin-profile.actions";

@Injectable()
export class AdminProfileEffects {
  constructor(
    private actions$: Actions,
    private adminService: AdminDashboardService
  ) {}

  loadProfileData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Profile.ProfileActionTypes.LoadProfiles),
      mergeMap(() =>
        this.adminService.getProfile().pipe(
          map((data: any) => ({
            type: Profile.ProfileActionTypes.LoadProfilesSuccess,
            user: data.data,
            message: data.message,
          })),
          catchError((err) =>
            of(
              new Profile.LoadProfilesFailure({
                error: err,
                message: err.error.message,
              })
            )
          )
        )
      )
    )
  );
}

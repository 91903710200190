<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <h1 class="text-white my-4">Change securely!</h1>
        <h4 class="text-white font-weight-normal">
          Change your account password and make your self more securely.
        </h4>
      </div>
    </div>
    <div class="auth-side-form">
      <div class="auth-content login">
        <h3 class="mb-4 f-w-400 text-center">Reset Your Password</h3>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" novalidate>
          <div class="form-group mb-3">
            <input type="text" [formControl]="resetPasswordForm.controls.nOTP" class="form-control" placeholder="OTP" />
            <div class="text-danger" *ngIf="
                resetPasswordForm.controls['nOTP'].hasError('required') &&
                (resetPasswordForm.controls['nOTP'].dirty ||
                  resetPasswordForm.controls['nOTP'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i>OTP is
              required
            </div>
          </div>
          <div class="form-group mb-3">
            <input type="password" [formControl]="resetPasswordForm.controls.sPassword" class="form-control"
              placeholder="New Password" />
            <div class="text-danger" *ngIf="
                resetPasswordForm.controls['sPassword'].hasError('required') &&
                (resetPasswordForm.controls['sPassword'].dirty ||
                  resetPasswordForm.controls['sPassword'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i> Password is
              required
            </div>
            <div class="text-danger" *ngIf="
                resetPasswordForm.controls['sPassword'].hasError('pattern')
              ">
              <i class="fa fa-exclamation-circle text-danger"></i>Password must
              be atleast 1 character, 1 number & without space allowed. Limited
              Password 6-12
            </div>
          </div>
          <div class="form-group mb-4">
            <input type="password" [formControl]="resetPasswordForm.controls.sReTypePassword" class="form-control"
              placeholder="Re-Type Password" />
            <div class="text-danger" *ngIf="
                resetPasswordForm.controls['sReTypePassword'].hasError(
                  'required'
                ) &&
                (resetPasswordForm.controls['sReTypePassword'].dirty ||
                  resetPasswordForm.controls['sReTypePassword'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i> Password
              confirmation is required
            </div>
            <div class="text-danger" *ngIf="
                resetPasswordForm.controls['sReTypePassword'].hasError(
                  'mustMatch'
                )
              ">
              <i class="fa fa-exclamation-circle text-danger"></i>Confirm
              password and password do not match.
            </div>
          </div>

          <button [disabled]="resetPasswordForm.invalid || btnDisable" class="btn btn-block btn-secondary mb-4">
            <span *ngIf="btnLoader" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="!btnLoader">Submit</span>
          </button>
          <div class="login">
            <a class="go-back" style="color: #0099cc" routerLink="/">
              GO BACK TO LOGIN.
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'feather icon-home',
        url: '/admin/dashboard'
      },
      {
        id: 'users',
        title: 'Manage Users',
        type: 'item',
        icon: 'feather icon-users',
        url: '/admin/users' 
      },
      {
        id: 'category',
        title: 'Manage Shop Categories',
        type: 'item',
        icon: 'feather icon-users',
        url: '/admin/shop-catergory'
      },
      {
        id: 'shop',
        title: 'Manage Shop NFT items',
        type: 'item',
        icon: 'feather icon-credit-card',
        url: '/admin/shop-nft'
      },
      {
        id: 'transaction',
        title: 'Transaction items',
        type: 'item',
        icon: 'feather icon-credit-card',
        url: '/admin/transaction/list'
      },
      {
        id: 'tokens',
        title: 'Send Virtual Tokens',
        type: 'item',
        icon: 'feather icon-settings',
        url: '/admin/virtual-token',
      }
      // {
      //   id: 'notifications',
      //   title: 'Manage Notifications',
      //   type: 'item',
      //   icon: 'feather icon-bell',
      //   url: '/admin/notifications'
      // },
      // {
      //   id: 'support',
      //   title: 'Support Request',
      //   type: 'item',
      //   icon: 'feather icon-phone-call',
      //   url: '/admin/support-request'
      // },
      // {
      //   id: 'referralAmount',
      //   title: 'Referral Amount',
      //   type: 'item',
      //   icon: 'feather icon-users',
      //   url: '/admin/referral-amount'
      // },
      
    ]
  } 
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}

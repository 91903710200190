import { Action } from "@ngrx/store";

export enum ProfileActionTypes {
  LoadProfiles = "[Profile] Load Profiles",
  LoadProfilesSuccess = "[Profile] Load Profiles Success",
  LoadProfilesFailure = "[Profile] Load Profiles Failure",
}

export class LoadProfiles implements Action {
  readonly type = ProfileActionTypes.LoadProfiles;
}

export class LoadProfilesSuccess implements Action {
  readonly type = ProfileActionTypes.LoadProfilesSuccess;
  constructor(public payload: any) {}
}

export class LoadProfilesFailure implements Action {
  readonly type = ProfileActionTypes.LoadProfilesFailure;
  constructor(public payload: any) {}
}

export type AdminProfileActions =
  | LoadProfiles
  | LoadProfilesSuccess
  | LoadProfilesFailure;

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminDashboardService {
  private baseUrl = environment.backendApi;

  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http.get(`${this.baseUrl}/profile/adminProfile`);
  }
  updateProfile(data: any) {
    return this.http.post(`${this.baseUrl}/profile/updateProfile`, data);
  }
  changePassword(data: any) {
    return this.http.post(`${this.baseUrl}/profile/change_password`, data);
  }

  adminLogout() {
    return this.http.get(`${this.baseUrl}/profile/logout`);
  }
}

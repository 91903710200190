import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.backendApi;
 
  constructor(private http: HttpClient, private router: Router) { }

  login(data: any): any {
    return this.http.post<any>(`${this.baseUrl}/auth/login`, data)
  }

  forgotPassword(data: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/auth/forgot_password`, data, { observe: 'response' })
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${this.baseUrl}/auth/password_reset`, data)
  }

  isLoggedIn(url: string): boolean {
      
    const storageValues = JSON.parse(localStorage.getItem('adminuser'));
    if (storageValues !== null && storageValues !== '') {
        if (url === '/' ) {
            this.router.navigate(['/admin/dashboard']);
            return false;
        } else {
            return true;
        }
    } else {
   
        if (url !== '/' && url !== '/forgot-password' && url !== '/reset-password') {
             this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
   
    }


}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbTabsetModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { NavRightComponent } from './nav-bar/nav-right/nav-right.component';
import { NavLeftComponent } from './nav-bar/nav-left/nav-left.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavItemComponent } from './navigation/nav-content/nav-item/nav-item.component';
import { NavGroupComponent } from './navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from './navigation/nav-content/nav-content.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationItem } from './navigation/navigation';


import { AdminRoutingModule } from './admin-routing.module';

import { ConfigurationComponent } from './configuration/configuration.component';
import { AdminComponent } from './admin.component';


import { SharedModule } from './../../shared/shared.module';

import { EffectsModule } from '@ngrx/effects';
import { AdminProfileEffects } from 'src/app/store/admin-profile/admin-profile.effects';
@NgModule({
  declarations: [
    AdminComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavRightComponent,
    ConfigurationComponent,
    // ToggleFullScreenDirective,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbCarouselModule,
    EffectsModule.forFeature([AdminProfileEffects])
  ],
  exports: [
    AdminComponent,
    NavContentComponent,
    NavCollapseComponent,
    NavigationComponent,
    NavGroupComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavRightComponent,
    
  ],
  providers: [NavigationItem],
  bootstrap: [AdminComponent]
})
export class AdminModule { }

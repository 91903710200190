<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <h1 class="text-white my-4">Welcome to Shiny NFT Admin!</h1>
        <!-- <h4 class="text-white font-weight-normal">
          Sign in to your account and get explore the Shiny NFT Phase 3.
        </h4> -->
      </div>
    </div>
    <div class="auth-side-form">
      <div class="auth-content login">
        <h3 class="mb-4 f-w-400 text-center">Sign In</h3>
        <form [formGroup]="adminLoginForm">
          <div class="form-group mb-3">
            <input type="email" [formControl]="adminLoginForm.controls.sEmail" class="form-control"
              placeholder="Email address" />
            <div class="text-danger" *ngIf="
                adminLoginForm.controls['sEmail'].hasError('required') &&
                (adminLoginForm.controls['sEmail'].dirty ||
                  adminLoginForm.controls['sEmail'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i> Email is
              required
            </div>
            <div class="text-danger" *ngIf="adminLoginForm.controls['sEmail'].hasError('pattern')">
              <i class="fa fa-exclamation-circle text-danger"></i>Please enter a
              valid email address
            </div>
          </div>
          <div class="form-group mb-4">
            <input type="password" [formControl]="adminLoginForm.controls.sPassword" class="form-control"
              placeholder="Password" />
            <div class="text-danger" *ngIf="
                adminLoginForm.controls['sPassword'].hasError('required') &&
                (adminLoginForm.controls['sPassword'].dirty ||
                  adminLoginForm.controls['sPassword'].touched)
              ">
              <i class="fa fa-exclamation-circle text-danger"></i> Password is
              required
            </div>
            <!-- <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="feather"  [ngClass]="{'icon-eye-off': !fieldTextType,'icon-eye': fieldTextType}" 
                      (click)="toggleFieldTextType()">
                    </i>
                  </span>
                </div> -->
          </div>
          <div class="remember-forgot-password">
            <a class="" style="color: #0099cc" routerLink="/forgot-password">
              Forgot Password?
            </a>
          </div>
          <button [disabled]="adminLoginForm.invalid || btnDisable" (click)="adminLogin()"
            class="btn btn-block btn-primary mb-0">
            <span *ngIf="btnLoader" class="spinner-border spinner-border-sm" role="status"></span>
            <span *ngIf="!btnLoader">Login</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>